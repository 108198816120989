
import { mapState } from 'vuex'
import Mobile from '@/mixins/mobile-mixin'
import RightSidebarSeoModal from '@/components/Modals/SidebarModal/RightSidebarSeoModal'
import URL from '@/mixins/url-mixin'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'ReinsuranceBanner',
  components: {
    RightSidebarSeoModal,
  },
  mixins: [Mobile, URL, i18nMx],
  props: {
    mkpDarkMode: { type: Boolean, default: false },
  },
  data() {
    return {
      showTruspilotWidget: false,
      activeBanner: 0,
      interval: null,
      customerCare: {
        isSidebarOpen: false,
        content: this.$t('home.customerCareSideBar'),
      },
      newProduct: {
        isSidebarOpen: false,
        content: this.$t('product.newProductBlock'),
      },
      proSellers: {
        isSidebarOpen: false,
        content: [this.$t('home.proSellersSideBar.part1'), this.$t('home.proSellersSideBar.part2')],
      },
      delivery: {
        isSidebarOpen: false,
        content: this.$t('home.deliverySideBar'),
      },
    }
  },
  computed: {
    ...mapState('dynamicContent', {
      co2: state => state.counters.co2,
      products: state => state.counters.products,
    }),
    contactFormLink() {
      return this.country?.contactFormLink[this.locale]
    },
  },
  beforeMount() {
    this.$root.$on('closeRightSidebar', () => {
      // Auto close when no selection
      this.closeRightSidebarModal()
    })
    this.$root.$on('openNewProductSidebar', () => {
      this.openNewProductSidebar()
    })
    this.$root.$on('openCustomerCareSidebar', () => {
      this.openCustomerCareSidebar()
    })
    this.$root.$on('openProSellersSidebar', () => {
      this.openProSellersSidebar()
    })
    this.$root.$on('openDeliverySidebar', () => {
      this.openDeliverySidebar()
    })
  },
  beforeDestroy() {
    this.$root.$off('closeRightSidebar')
    this.$root.$off('openNewProductSidebar')
    this.$root.$off('openCustomerCareSidebar')
    this.$root.$off('openProSellersSidebar')
    this.$root.$off('openDeliverySidebar')
    clearInterval(this.interval)
  },
  mounted() {
    this.showTruspilotWidget = true
    if (this.isMd && !this.mkpDarkMode) {
      this.interval = setInterval(() => this.updateActiveSlide(), 3000)
    }
  },
  methods: {
    closeRightSidebarModal() {
      this.newProduct.isSidebarOpen = false
      this.customerCare.isSidebarOpen = false
      this.proSellers.isSidebarOpen = false
      this.delivery.isSidebarOpen = false
    },
    openNewProductSidebar() {
      this.newProduct.isSidebarOpen = true
      this.newProduct.content = this.$t('product.newProductBlock', {
        co2Saved: this.co2.toLocaleString(),
        productQuantity: this.products.toLocaleString(),
      })
    },
    openCustomerCareSidebar() {
      this.customerCare.isSidebarOpen = true
      // this.customerCare.content = this.$t('home.customerCareSideBar')
    },
    openProSellersSidebar() {
      this.proSellers.isSidebarOpen = true
      // this.proSellers.content = [this.$t('home.proSellersSideBar.part1'), this.$t('home.proSellersSideBar.part2')]
    },
    openDeliverySidebar() {
      this.delivery.isSidebarOpen = true
      // this.delivery.content = this.$t('home.deliverySideBar')
    },
    openLink(url, blank = true) {
      const link = document.createElement('a')
      link.href = url
      if (blank) {
        link.setAttribute('target', '_blank')
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    updateActiveSlide(i) {
      if (i && i < 3) {
        this.activeBanner = i
      } else if (this.activeBanner < 3) {
        this.activeBanner++
      } else {
        this.activeBanner = 0
      }
    },
  },
}
