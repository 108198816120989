
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'RightSidebarSeoModal',
  mixins: [Mobile],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
    classVariant: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasHeaderInfoSlot() {
      return !!this.$slots['header-info']
    },
    actionPayload() {
      return this.$store.getters['stocks/actionPayload']
    },
  },
  methods: {
    close() {
      this.$root.$emit('closeRightSidebar')
    },
  },
}
